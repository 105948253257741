export default {
    title: 'IKEA Business Share of Sales',
    location: '/business/ikea-business-share-of-sales-b2b',
    tabs: [
        // {
        //     name: 'By Country',
        //     link: `/business/ikea-business-share-of-sales-b2b`
        // },
        // {
        //     name: 'Trend',
        //     link: `/business/ikea-business-share-of-sales-b2b/:market`
        // },
        // {
        //     name: 'Top 10',
        //     link: '/business/ikea-business-share-of-sales-b2b/top-10'
        // }
    ]
}